@import "./app-header.scss";
@import "./instructions.scss";
@import "./loader.scss";
@import "./shared.scss";

body {
    display: flex;
    flex-flow: row wrap;
    font-family: sans-serif;
}

.body-container {
    padding-top: 80px;
    margin-left: 60px;
    margin-right: 60px;
    width: 100%;

    @media (max-width: 600px) {
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 20px;
    }
}

.login-container {
    z-index: 10001;
    width: 100%;
}

html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}

#root {
    width: 100%;
}