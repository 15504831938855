.instructions-container {
    margin-top: 30px;
    margin-right: 90px;
    position: fixed;

    h4 {
        margin-bottom: 0px;
    }

    &>ul{
        margin-top: 0px;
    }

    li{
        padding-top: 10px;
    }

    ol>li {
        padding-top: 10px;
    }

    @media (max-width: 600px) {
        position: relative;
        margin-right: 20px;
        margin-bottom: -60px;
    }
}