@import "./shared.scss";

header {
    background-color: $main-color;
    color: $second-color;
    height: 70px;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    font-size: 60px;
    display: flex;
    justify-content: space-between;
    z-index: 10000;

    .header-child {
        width: 250px;
    }

    @media (max-width: 600px) {
        font-size: 30px;
        height: 35px;
        justify-content: left;

        .header-child {
            width: auto;
        }

        mgt-login {
            margin-top: -7px;
            --color: $second-color;
        }
    }
}

mgt-login {
    margin-top: -10px;
    --color: $second-color;
}